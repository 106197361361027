import React, { useState } from "react";
import "./HomePageStyle.css";
import { AiOutlineSearch } from "react-icons/ai";
import pexelse from "../../assets/images/pexels-e.jpg";
import AllItems from "../../components/allitems/AllItems";
import SubscribeFormComponent from "../../components/subscribeform/SubscribeFormComponent";
import { useDispatch } from "react-redux";
import { getSearchItemsData } from "../../services/actions/getSearchItems";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";

// import TestComponent from "../components/TestComponent";

function HomePage() {
  const [searchData, setSearchData] = useState("");
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(getSearchItemsData(searchData));
    navigate("/search/");
  };
  return (
    <div>
      <Helmet>
        <title>
          OSTORE-SMART | Online shopping for Women, Men, Kids Fashion &
          Lifestyle
        </title>
      </Helmet>
      <div className="home-page-container">
        <div className="home-page-img-container">
          <img src={pexelse} alt="" className="home-page-image" />
          {/* <h1 className="home-page-welcome">Welcome to my page</h1>
      <h1 className="home-page-welcome-two">Feel free to shop</h1> */}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="search-container">
            <input
              name="searchProduct"
              type="search"
              className="search-input-field"
              placeholder="Search products..."
              onChange={(e) => {
                setSearchData(e.target.value);
              }}
            />
            <button type="submit" className="search-button">
              <AiOutlineSearch />
            </button>
          </div>
        </form>
        <h1 style={{ textAlign: "center" }} className="our-products">
          Latest Products
        </h1>
        <AllItems />
        <SubscribeFormComponent />
      </div>
    </div>
  );
}

export default HomePage;
