import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import amazonbuttonlogo from "../../assets/images/amazon-logo-transparent-1.png";
// import buynow from "../../assets/images/buy-now-button-png-2.png";
import "./SearchItemsStyle.css";

function SearchItems() {
  let navigate = useNavigate();

  const afSearchListItems = useSelector((state) => state.afSearchItems.items);

  const afSearchListItemsLength = afSearchListItems.length;

  const goToSingleItemPage = (id) => {
    let path = `/product/${id}/store/`;
    navigate(path);
  };
  return (
    <>
      <h1 className="product-found">
        You found <b>{afSearchListItemsLength} </b>
        {afSearchListItemsLength === 1 ? "product" : "products"}
      </h1>
      <div className="items-container">
        {afSearchListItems.map((item) => {
          const shortenTitles =
            item.title.length > 50
              ? item.title.substring(0, 50) + "..."
              : item.title;
          return (
            <div key={item.id} className="item-card-container">
              <h2
                className="item-card-title"
                onClick={() => goToSingleItemPage(item.id)}
              >
                {shortenTitles}
              </h2>
              <img
                src={item?.image}
                alt=""
                className="item-card-image"
                onClick={() => goToSingleItemPage(item.id)}
              />

              <div className="item-card-amazon">
                {item.afflink && (
                  <div className="item-card-amazon-display">
                    <a href={item.afflink} target="_blank" rel="noreferrer">
                      <img
                        src={amazonbuttonlogo}
                        alt=""
                        className="item-card-amazon-image"
                      />{" "}
                    </a>
                  </div>
                )}

                {item.seccondafflink && (
                  <div className="item-card-amazon-display">
                    <a
                      href={item.seccondafflink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {/* <img
                      src={buynow}
                      alt=""
                      className="item-card-website-image"
                    />{" "} */}
                      <button className="item-cb-button">Shop now</button>
                    </a>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default SearchItems;
