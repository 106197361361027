import React from "react";
import SearchItems from "../../components/searchitems/SearchItems";

function SearchItemsPage() {
  return (
    <div>
      <SearchItems />
    </div>
  );
}

export default SearchItemsPage;
