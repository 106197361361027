import React from "react";
import "./StorePageStyle.css";
import StoreItems from "../../components/storeitems/StoreItems";

import { Helmet } from "react-helmet";

function StorePage() {
  return (
    <div>
      <Helmet>
        <title>
          OSTORE-SMART | Store Page, check out all the store products
        </title>
      </Helmet>
      <div className="main-store-container">
        <StoreItems />
      </div>
    </div>
  );
}

export default StorePage;
