import React, { useState, useEffect } from "react";
import "./SubscribeForm.css";
import postSubscription from "../../services/postSubscription";
import { BACKEND_API_POST_SUBSCRIBE } from "../../constants/constants";

function SubscribeFormComponent() {
  const [subscriberName, setName] = useState("");
  const [subscriberEmail, setEmail] = useState("");

  const [responseRecived, setResponse] = useState("");
  const [subscribed, setSubscribed] = useState(true);

  const handleSubscription = (e) => {
    e.preventDefault();
    postSubscription(BACKEND_API_POST_SUBSCRIBE, {
      email: subscriberEmail,
      name: subscriberName,
    }).then((response) => {
      if (!response.ok) {
        setResponse("You have already subscribed .");
      } else if (response.ok) {
        setResponse("You have subscribed successfully.");
      }
    });

    setName("");
    setEmail("");
  };

  useEffect(() => {
    if (responseRecived.length > 10) {
      setSubscribed(false);
    }
  }, [responseRecived]);

  return (
    <div className="subscribe-form-container">
      <form onSubmit={handleSubscription}>
        <h1 className="subscribe-label">
          {subscribed ? "Subscribe for the latest products" : responseRecived}
        </h1>

        <br />
        <label htmlFor="" className="subscribe-form-label">
          Name
        </label>
        <br />
        <input
          type="text"
          className="subscribe-form-input"
          onChange={(e) => setName(e.target.value)}
          value={subscriberName}
        />
        <br />
        <br />
        <br />
        <label htmlFor="" className="subscribe-form-label">
          Email
        </label>
        <br />
        <input
          type="email"
          className="subscribe-form-input"
          onChange={(e) => setEmail(e.target.value)}
          value={subscriberEmail}
          required
        />
        <br />

        <button type="submit" className="subscribe-submit-button">
          Subscribe
        </button>
      </form>
    </div>
  );
}

export default SubscribeFormComponent;
