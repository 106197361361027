import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "../../constants/constants";

export const getItemData = createAsyncThunk(
  "itemData/getItemData",
  async (data) => {
    return fetch(`${BACKEND_API_URL}item/${data.id}/`).then((res) =>
      res.json()
    );
  }
);

const itemSlice = createSlice({
  name: "itemData",
  initialState: {
    items: [],
    status: "loading",
    errMessage: "",
  },
  extraReducers: {
    [getItemData.pending]: (state) => {
      state.items = [];
    },
    [getItemData.fulfilled]: (state, { payload }) => {
      state.items = payload;
      state.status = "fulfilled";
    },
    [getItemData.rejected]: (state, { payload }) => {
      state.errMessage = `${payload}`;
    },
  },
});

export default itemSlice;
