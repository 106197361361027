export const WEBSITE_FIRST_NAME = "OSTORE";
export const WEBSITE_LAST_NAME = "SMART";

export const BACKEND_API_URL = "https://greatsales.herokuapp.com/";
export const BACKEND_API_SEARCH_URL =
  "https://greatsales.herokuapp.com/product/items/?search=";

export const BACKEND_API_POST_MESSAGE =
  "https://greatsales.herokuapp.com/contact/";

  export const BACKEND_API_POST_SUBSCRIBE =
  "https://greatsales.herokuapp.com/subscribe/";
