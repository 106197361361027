import React, { useState } from "react";
import "./StoreItemsStyle.css";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPaginatedItems } from "../../services/actions/getPageinatedItems";

import { useEffect } from "react";
import { useSelector } from "react-redux";

import amazonbuttonlogo from "../../assets/images/amazon-logo-transparent-1.png";
// import buynow from "../../assets/images/buy-now-button-png-2.png";

import {
  MdKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

function StoreItems() {
  const [loadItems, setLoadItems] = useState(1);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaginatedItems(`${loadItems}`));
  }, [dispatch, loadItems]);

  const afPageinateItems = useSelector((state) => state.afPaginateItems.items);
  const afProductNumber = useSelector((state) => state.afPaginateItems.count);

  console.log();

  const numberOfPages = Math.ceil(afProductNumber / 3);

  //   useEffect(() => {
  //     dispatch(paginateActions.addItems(afPageinateItems));
  //   }, [afPageinateItems, dispatch, loadItems]);

  //   const afAllPaginateItems = useSelector(
  //     (state) => state.afAllPaginateItems.items
  //   );

  const paginateForwards = () => {
    if (loadItems < afProductNumber / 3) {
      setLoadItems(loadItems + 1);
    }
  };

  const paginateBacwards = () => {
    if (loadItems > 1) {
      setLoadItems(loadItems - 1);
    }
  };

  const goToSingleItemPage = (id) => {
    let path = `/product/${id}/store/`;
    navigate(path);
  };

  return (
    <div>
      <h1 style={{ textAlign: "center", marginTop: "50px" }}>
        Page {loadItems} of {numberOfPages}
      </h1>
      <div className="store-store-items-container">
        <div className="store-items-container">
          {afPageinateItems.map((item) => {
            const shortenTitles =
              item.title.length > 50
                ? item.title.substring(0, 50) + "..."
                : item.title;
            return (
              <div key={item.id} className="store-item-card-container">
                <h2
                  className="store-item-card-title"
                  onClick={() => goToSingleItemPage(item.id)}
                >
                  {shortenTitles}
                </h2>
                <img
                  src={item?.image}
                  alt=""
                  className="store-item-card-image"
                  onClick={() => goToSingleItemPage(item.id)}
                />

                <div className="store-item-card-amazon">
                  {item.afflink && (
                    <div className="store-item-card-amazon-display">
                      <a href={item.afflink} target="_blank" rel="noreferrer">
                        <img
                          src={amazonbuttonlogo}
                          alt=""
                          className="store-item-card-amazon-image"
                        />{" "}
                      </a>
                    </div>
                  )}

                  {item.seccondafflink && (
                    <div className="store-item-card-amazon-display">
                      <a
                        href={item.seccondafflink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {/* <img
                      src={buynow}
                      alt=""
                      className="store-item-card-website-image"
                    />{" "} */}
                        <button className="store-item-cb-button">
                          Shop now
                        </button>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <button onClick={paginateBacwards} className="store-left-arrow">
          <MdKeyboardArrowLeft />
        </button>
        <button onClick={paginateForwards} className="store-right-arrow">
          <MdOutlineKeyboardArrowRight />
        </button>
      </div>
    </div>
  );
}

export default StoreItems;
