import React from "react";
import { Link } from "react-router-dom";
import "./NavBarStyle.css";
import { WEBSITE_FIRST_NAME } from "../constants/constants";
import { WEBSITE_LAST_NAME } from "../constants/constants";
import { HiMenu } from "react-icons/hi";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { drawerActions } from "../services/actions/openDrawer";

function NavBarComponent() {
  const dispatch = useDispatch();

  const drawerState = useSelector((state) => state.drawer.drawerState);

  return (
    <div>
      <div className="nav-bar-container">
        <div className="navbar-header">
          <HiMenu
            style={{ fontSize: "40px", marginRight: "10px" }}
            className="toggler-button"
            onClick={() => {
              dispatch(drawerActions.toggleDrawer(!drawerState));
            }}
          />
          <Link to="/">
            <h1 className="navbar-header-name">
              {WEBSITE_FIRST_NAME}
              {"-"}
              <span className="header-name-span">{WEBSITE_LAST_NAME}</span>
            </h1>
          </Link>
        </div>

        <div className="nav-bar-links">
          <Link to="/" className="nav-bar-link">
            Home
          </Link>
          <Link to="/store/" className="nav-bar-link">
            Store
          </Link>
          <Link to="/about/" className="nav-bar-link ">
            About Us
          </Link>
        </div>

        <div
          className={
            !drawerState
              ? "drawer-container show-drawer"
              : "drawer-container hide-drawer "
          }
          onClick={() => {
            dispatch(drawerActions.toggleDrawer(!drawerState));
          }}
        >
          <ul>
            <Link to="/">Home</Link>
            <Link to="/store/">Store</Link>
            <Link to="/about/">About Us</Link>
            <Link to="/contact/">Contact</Link>
            <a
              href="https://www.instagram.com/oraldohysaj/"
              target="_blank"
              rel="noreferrer"
              className="icon-style"
            >
              <AiOutlineInstagram className="icon-background" />
            </a>
            <a
              href="https://wa.me/"
              target="_blank"
              rel="noreferrer"
              className="icon-style"
            >
              <AiOutlineWhatsApp className="icon-background" />
            </a>
            <a
              href="https://www.facebook.com/oraldo.hysaj/"
              target="_blank"
              rel="noreferrer"
              className="icon-style"
            >
              <AiOutlineFacebook className="icon-background" />
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NavBarComponent;
