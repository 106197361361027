import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BACKEND_API_SEARCH_URL } from "../../constants/constants";

export const getSearchItemsData = createAsyncThunk(
  "searchItemData/getSearchItemsData",
  async (data) => {
    console.log("getSearchItemsData", data);
    return fetch(`${BACKEND_API_SEARCH_URL}${data}`).then((res) => res.json());
  }
);

const searchItemsSlice = createSlice({
  name: "searchItemData",
  initialState: {
    items: [],
    status: "loading",
    errMessage: "",
  },
  extraReducers: {
    [getSearchItemsData.pending]: (state) => {
      state.items = [];
    },
    [getSearchItemsData.fulfilled]: (state, { payload }) => {
      state.items = payload;
      state.status = "fulfilled";
    },
    [getSearchItemsData.rejected]: (state, { payload }) => {
      state.errMessage = `${payload}`;
    },
  },
});

export default searchItemsSlice;
