import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
  name: "drawer",
  initialState: {
    drawerState: true,
  },
  reducers: {
    toggleDrawer(state, action) {
      const newState = action.payload;
      state.drawerState = newState;
    },
  },
});

export const drawerActions = drawerSlice.actions;
export default drawerSlice;
