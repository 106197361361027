import { configureStore } from "@reduxjs/toolkit";
import itemsSlice from "../actions/getItems";
import itemSlice from "../actions/getSingleItem";
import drawerSlice from "../actions/openDrawer";
import searchItemsSlice from "../actions/getSearchItems";
import itemsPaginateSlice from "../actions/getPageinatedItems";
import allPaginatedImtesSlice from "../actions/allPaginatedItems";

const store = configureStore({
  reducer: {
    afItems: itemsSlice.reducer,
    drawer: drawerSlice.reducer,
    afItem: itemSlice.reducer,
    afSearchItems: searchItemsSlice.reducer,
    afPaginateItems: itemsPaginateSlice.reducer,
    afAllPaginateItems: allPaginatedImtesSlice.reducer,
  },
});
export default store;
