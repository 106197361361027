import { createSlice } from "@reduxjs/toolkit";

const allPaginatedImtesSlice = createSlice({
  name: "allPaginatedItems",
  initialState: {
    items: [],
  },

  reducers: {
    addItems(state, { payload }) {
      state.items.push(...payload);
    },
  },
});

export const paginateActions = allPaginatedImtesSlice.actions;
export default allPaginatedImtesSlice;
