import React from "react";
import "./AboutPageStyle.css";
import { WEBSITE_FIRST_NAME } from "../../constants/constants";
import { WEBSITE_LAST_NAME } from "../../constants/constants";

import { Helmet } from "react-helmet";

function AboutPage() {
  return (
    <div>
      <Helmet>
        <title>OSTORE-SMART | About Page</title>
      </Helmet>
      <div className="about-text-wrapper">
        <h1 className="aboutus-text">ABOUT US</h1>
      </div>

      <h1 className="get-what-you-need">Get What You Need In A CLICK!</h1>
      <p className="aboutus-descripion">
        Based in Staten Island, New York ,
        <span style={{ color: "red" }}>
          {" "}
          {WEBSITE_FIRST_NAME} {WEBSITE_LAST_NAME}
        </span>{" "}
        , is a shopping website in partnership with amazon products and other
        partners. We belive the best way to satisfy our customers is by deeply
        understanding what they need and love. Providing the best quality
        products for a great price is our goal. We want our customers to be
        happy, so when our customers are happy that makes us happy.
      </p>
    </div>
  );
}

export default AboutPage;
