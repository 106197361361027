import React, { useState } from "react";
import "./FooterComponentStyle.css";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { Link } from "react-router-dom";

function FooterComponent() {
  const [loadProducts, setLoadProducts] = useState(false);
  const [loadStore, setLoadStore] = useState(false);
  const [loadAbout, setLoadAbout] = useState(false);

  const goOnTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="footer-container">
      <div
        className="first-component"
        onClick={() => {
          setLoadProducts(!loadProducts);
          window.scrollTo(0, document.body.scrollHeight);
        }}
      >
        <h1>
          Products{" "}
          {loadProducts ? (
            <IoIosArrowDropup className="footer-dropdown-icon" />
          ) : (
            <IoIosArrowDropdown className="footer-dropdown-icon" />
          )}
        </h1>
        <ul className={loadProducts ? "" : "products-items"}>
          <li>Fitness</li>
          <li>Cosmetics</li>
          <li>Health</li>
          <li>Electronics</li>
        </ul>
      </div>
      <div
        onClick={() => {
          setLoadStore(!loadStore);
          window.scrollTo(0, document.body.scrollHeight);
        }}
      >
        <h1>
          Store{" "}
          {loadStore ? (
            <IoIosArrowDropup className="footer-dropdown-icon" />
          ) : (
            <IoIosArrowDropdown className="footer-dropdown-icon" />
          )}
        </h1>
        <ul className={loadStore ? "" : "store-items"}>
          <li>
            <Link
              to="/store/"
              style={{ textDecoration: "none", color: "white" }}
            >
              Products
            </Link>
          </li>
          <li>
            <Link
              to="/"
              style={{ textDecoration: "none", color: "white" }}
              onClick={goOnTop}
            >
              Search
            </Link>
          </li>
        </ul>
      </div>
      <div
        onClick={() => {
          setLoadAbout(!loadAbout);
          window.scrollTo(0, document.body.scrollHeight);
        }}
      >
        <h1>
          About{" "}
          {loadAbout ? (
            <IoIosArrowDropup className="footer-dropdown-icon" />
          ) : (
            <IoIosArrowDropdown className="footer-dropdown-icon" />
          )}
        </h1>
        <ul className={loadAbout ? "" : "about-items"}>
          <li>
            <Link
              to="/about/"
              style={{ textDecoration: "none", color: "white" }}
            >
              About Page
            </Link>
          </li>
          <li>
            <a
              href="https://www.facebook.com/oraldo.hysaj/"
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: "30px",
              }}
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineFacebook />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/oraldohysaj/"
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: "30px",
              }}
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineInstagram />
            </a>
          </li>
          <li>
            <a
              href="https://wa.me/"
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: "30px",
              }}
            >
              <AiOutlineWhatsApp />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default FooterComponent;
