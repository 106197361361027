import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getItemsData } from "../../services/actions/getItems";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import amazonbuttonlogo from "../../assets/images/amazon-logo-transparent-1.png";
import buynow from "../../assets/images/buy-now-button-png-2.png";

import "./AllItemsStyle.css";

function AllItems() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getItemsData());
  }, [dispatch]);

  const afListItems = useSelector((state) => state.afItems.items);

  const goToSingleItemPage = (id) => {
    let path = `/product/${id}/store/`;
    navigate(path);
  };

  return (
    <div className="all-items-container">
      {afListItems.map((item) => {
        const shortenTitles =
          item.title.length > 50
            ? item.title.substring(0, 50) + "..."
            : item.title;

        return (
          <div key={item.id} className="all-item-card-container">
            <h2
              className="all-item-card-title"
              onClick={() => goToSingleItemPage(item.id)}
            >
              {shortenTitles}
            </h2>
            <img
              src={item?.image}
              alt=""
              className="all-item-card-image"
              onClick={() => goToSingleItemPage(item.id)}
            />

            <div className="all-item-card-amazon">
              {item.afflink && (
                <div className="all-item-card-amazon-display">
                  <a href={item.afflink} target="_blank" rel="noreferrer">
                    <img
                      src={amazonbuttonlogo}
                      alt=""
                      className="all-item-card-amazon-image"
                    />{" "}
                  </a>
                </div>
              )}

              {item.seccondafflink && (
                <div className="all-item-card-amazon-display">
                  <a
                    href={item.seccondafflink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* <img
                      src={buynow}
                      alt=""
                      className="all-item-card-website-image"
                    />{" "} */}
                    <button className="all-item-cb-button">Shop now</button>
                  </a>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AllItems;
