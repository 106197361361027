import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "../../constants/constants";

export const getPaginatedItems = createAsyncThunk(
  "paginateItems/getPaginatedItems",
  async (data) => {
    if (data) {
      if (data) {
        return fetch(`${BACKEND_API_URL}product/items/page/?page=${data}`).then(
          (res) => res.json()
        );
      }
    }
    return fetch(`${BACKEND_API_URL}product/items/page/`).then((res) =>
      res.json()
    );
  }
);

const itemsPaginateSlice = createSlice({
  name: "paginateItems",
  initialState: {
    items: [],
    count: 0,
    status: "loading",
    errMessage: "",
  },
  extraReducers: {
    [getPaginatedItems.pending]: (state) => {
      state.items = [];
    },
    [getPaginatedItems.fulfilled]: (state, { payload }) => {
      state.items = payload.results;
      state.count = payload.count;
      state.status = "fulfilled";
    },
    [getPaginatedItems.rejected]: (state, { payload }) => {
      state.errMessage = `${payload}`;
    },
  },
});

export default itemsPaginateSlice;
