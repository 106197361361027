import "./App.css";
import LoadingLine from "./components/loadingline/LoadingLine";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NavBarComponent from "./layouts/NavBarComponent";
import FooterComponent from "./layouts/FooterComponent";

import HomePage from "./pages/homepage/HomePage";
import SingleProductPage from "./pages/singleproductpage/SingleProductPage";
import AboutPage from "./pages/aboutpage/AboutPage";
import StorePage from "./pages/storepage/StorePage";
import SearchItemsPage from "./pages/searchitemspage/SearchItemsPage";
import ContactPage from "./pages/contactpage/ContactPage";

import { useSelector, useDispatch } from "react-redux";
import { drawerActions } from "./services/actions/openDrawer";

function App() {
  const dispatch = useDispatch();

  const drawerState = useSelector((state) => state.drawer.drawerState);
  return (
    <div
      onClick={() => {
        if (drawerState === false) {
          dispatch(drawerActions.toggleDrawer(!drawerState));
        }
      }}
    >
      <Router>
        <LoadingLine />
        <NavBarComponent />
        <main className="main-section">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/store/" element={<StorePage />} />
            <Route path="/about/" element={<AboutPage />} />
            <Route path="/search/" element={<SearchItemsPage />} />
            <Route path="/contact/" element={<ContactPage />} />
            <Route path="/product/:id/store/" element={<SingleProductPage />} />
          </Routes>
        </main>
        <FooterComponent />
      </Router>
    </div>
  );
}

export default App;
