import React, { useState } from "react";
import "./ContactFormStyle.css";
import { BACKEND_API_POST_MESSAGE } from "../../constants/constants";

import postMessage from "../../services/postMessage";

function ContactFormComponent() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [isSent, setIsSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    postMessage(BACKEND_API_POST_MESSAGE, {
      email: email,
      message_text: message,
    }).then(setIsSent(true));

    setEmail("");
    setMessage("");
  };

  return (
    <div className="contact-form-container">
      {isSent ? (
        <div>
          <h1>Your message is sent.</h1> <br />{" "}
          <h1>We will be in contact shortly!</h1>{" "}
        </div>
      ) : (
        <h1>Contact Form</h1>
      )}

      <form onSubmit={handleSubmit}>
        <h2 className="email-label">Email</h2>
        <input
          type="email"
          className="contact-email-input"
          required
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
        />

        <h2 className="message-label">Message</h2>
        <textarea
          type="text"
          className="contact-message-input"
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          value={message}
        />
        <br />
        <button type="submit" className="contact-submit-button">
          Send
        </button>
      </form>
    </div>
  );
}

export default ContactFormComponent;
