import React from "react";
import "./ContactPageStyle.css";
import ContactFormComponent from "../../components/contactform/ContactFormComponent";
import gifimg from "../../assets/images/gif-img.gif";

function ContactPage() {
  return (
    <div className="contact-page-container">
      <ContactFormComponent />
      <div className="contact-gif-container">
        <img src={gifimg} alt="" className="gif-img" />
      </div>
    </div>
  );
}

export default ContactPage;
