import React, { useEffect } from "react";
import "./SingleProductPageStyle.css";
import { useSelector, useDispatch } from "react-redux";
import { getItemData } from "../../services/actions/getSingleItem";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet";

import amazonbuttonlogo from "../../assets/images/amazon-logo-transparent-1.png";
import buynow from "../../assets/images/buy-now-button-png-2.png";

function SingleProductPage() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const afSingleItem = useSelector((state) => state.afItem.items);

  useEffect(() => {
    dispatch(getItemData({ id }));
  }, [dispatch, id]);

  return (
    <div className="main-single-product-container">
      <Helmet>
        <title>{afSingleItem.title}</title>
      </Helmet>
      <div className="single-product-container">
        <div className="single-product-title-slmall-screen-display">
          <h1 className="single-product-title-sm-screen">
            {afSingleItem.title}
          </h1>
        </div>
        <div className="single-product-image-container">
          <img
            src={afSingleItem.image}
            alt="Product loading..."
            className="single-product-image"
          />
        </div>

        <div className="single-product-detail-container">
          <h1
            className="single-product-title"
            dangerouslySetInnerHTML={{ __html: afSingleItem.title }}
          ></h1>

          <div
            className="single-product-description"
            dangerouslySetInnerHTML={{ __html: afSingleItem.description }}
          ></div>

          <h1 className="single-product-buy-on">
            Get product on : {afSingleItem.website_name}
          </h1>

          <div className="single-item-card-amazon">
            {afSingleItem.afflink && (
              <div className="single-item-card-amazon-display">
                <a
                  href={afSingleItem.afflink}
                  alt={afSingleItem.title}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={amazonbuttonlogo}
                    alt=""
                    className="single-item-card-amazon-image"
                  />{" "}
                </a>
              </div>
            )}
            {afSingleItem.seccondafflink && (
              <div
                className={
                  afSingleItem.afflink
                    ? "single-item-card-amazon-display"
                    : "single-item-card-amazon-display set-web-link-width"
                }
              >
                <a
                  href={afSingleItem.seccondafflink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* <img
                    src={buynow}
                    alt={afSingleItem.title}
                    className="single-item-card-website-image"
                  />{" "} */}

                  <button className="single-item-cb-button">Shop now</button>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="single-product-aditional_informations"
        dangerouslySetInnerHTML={{
          __html: afSingleItem.aditional_informations,
        }}
      ></div>

      <div
        className="amazon-generated-html"
        dangerouslySetInnerHTML={{
          __html: afSingleItem.amazon_text_image,
        }}
      ></div>
    </div>
  );
}

export default SingleProductPage;
